<template>
  <div class="accrditation-page-wrapper">
    <el-card class="accraditation-page">
      <el-form class="accraditation-seach-bar" slot="header" size="mini">
        <el-form-item label="任务名称"></el-form-item>
      </el-form>
      <el-table :data="accraditations" :height="tHeight"></el-table>
      <el-row class="pagination-bar">
        <el-pagination background layout="total,prev, pager, next" :current-page="page" :page-size="size" @current-change="handleCurrentChange" :total="total"></el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { approvalCenterList } from '@/api/accraditation.js';
import statusInclude from '@/utils/statusCode';
import sleep from '@/utils/sleep';

export default {
  name: 'Accraditation',
  data() {
    return {
      tHeight: 0,
      page: 1,
      size: 13,
      total: 0,
      accraditations: [],
    };
  },
  created() {
    this.getHeight();
  },
  mounted() {
    this.getSourceSize(200).then((size) => {
      this.size = size;
      this.approvalCenterList(this.page, this.size);
    });
  },
  methods: {
    getHeight() {
      this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },
    handleCurrentChange() {},
    //表格高度请求所需要请求的数据size
    async getSourceSize(time) {
      await sleep(time);
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51);
    },
    async approvalCenterList(page, size, name = '', approvalStatus = '', status = '') {
      const { code, reason, data } = await approvalCenterList(page, size, name, approvalStatus, status);
      if (!statusInclude(code)) return this.$message.error(`请求错误：${reason}`);
      const { records } = data;
      this.accraditations = records;
      this.total = total;
    },
  },
};
</script>

<style lang="scss" scoped>
.accraditation-page-wrapper {
  ::v-deep .el-card__body {
    height: calc(100vh - 185px);
    position: relative;
  }
}
</style>
